.sketch-category {
  /* background-color: var(--grey); */
  font-size: 22.4px;
  font-size: 1.4rem;
  margin: 16px 0;
  margin: 1rem 0;
}

  .sketch-category-active {
    border-left: 4.8px solid #ff6c16;
    border-left: .3rem solid #ff6c16;
  }

  .sketch-category-title {
    padding: 16px;
    padding: 1rem
  }

  .sketch-category-title i {
  font-size: 30.4px;
  font-size: 1.9rem;
}

.sketch-sub-category {
  margin-left: 32px;
  margin-left: 2rem;
}

.sketch-item-list {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sketch-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  padding: 1rem;
  width: 192px;
  width: 12rem;
  text-align: center;
  background-color: white;
  color: black;
  border-radius: .5rem;
}

.sketch-item-image {
    background-color: white;
    width: 100%;
  }
