.input-title {
  font-size: 20.8px;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 4.8px;
  letter-spacing: .3rem;
}

.input-holder {
  margin-top: 16px;
  margin-top: 1rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.form-input {
  width: 100%;
  height: 64px;
  height: 4rem;
  border: none;
  font-size: 24px;
  font-size: 1.5rem;
  outline: none;
  padding-left: 32px;
  padding-left: 2rem;
  -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
  letter-spacing: 3.2px;
  letter-spacing: .2rem;
  border-radius: .5rem
}

.form-input::-webkit-input-placeholder {
  opacity: .5;
}

.form-input::-ms-input-placeholder {
  opacity: .5;
}

.form-input::placeholder {
  opacity: .5;
}

.error-input {
  border: solid 3.2px #d2122c !important;
  border: solid .2rem #d2122c !important;
}

.error-input-message {
    position: absolute;
    color: #d2122c;
    top: 112px;
    top: 7rem;
    margin-left: 16px;
    margin-left: 1rem;
  }

.flex-row-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.single-row {
  width: 100%;
  position: relative;
}

.double-row {
  width: 45%;
  position: relative;
}

.triple-row {
  width: 30%;
  position: relative;
}

.quad-row {
  width: 17.5%;
  position: relative;
}

.quint-row {
  width: 10%;
  position: relative;
}

.required-input {
  color: #d2122c;
  position: relative;
  top: -4.8px;
  top: -.3rem;
}
