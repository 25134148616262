.phisher-button {
  border: 3.2px #ff6c16 solid;
  border: .2rem #ff6c16 solid;
  padding: 16px 56px;
  padding: 1rem 3.5rem;
  letter-spacing: 4.8px;
  letter-spacing: .3rem;
  font-size: 24px;
  font-size: 1.5rem;
  border-radius: .5rem;
  z-index: 1;
  position: relative;
  background-color: transparent;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.phisher-button:after {
  content: '';
  background-color: #ff6c16;
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: .1rem;
  width: 7%;
  -webkit-transition: width .2s;
  transition: width .2s;
}

.phisher-button:hover::after {
  width: 100%;
}

.phisher-button:hover {
  color: white;
}

.dark-button {
  color: white !important;
}
