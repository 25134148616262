.confirm-container {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  right: 0 !important;
  top: 120% !important;
  z-index: 900;
}

.confirm-menu {
  position: absolute;
  background-color: #f7f7f7;
  border-radius: .5rem;
  color: black;
  top: 64px;
  top: 4rem;
  padding: 8px 0;
  padding: .5rem 0;
  right: -16px;
  right: -1rem;
  -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
}

.confirm-menu-list-item {
    padding: 16px 32px;
    padding: 1rem 2rem;
    width: 272px;
    width: 17rem
  }

.confirm-menu-list-item i {
  font-size: 27.2px;
  font-size: 1.7rem;
}

.confirm-menu-list-item:hover {
  background-color: #ff6c16;
  color: white;
}

.confirm-triangle {
  color: #f7f7f7;
  font-size: 28.8px;
  font-size: 1.8rem;
  position: absolute;
  top: -21.6px;
  top: -1.35rem;
  right: 12.8px;
  right: .8rem;
  text-shadow: 0px -1.6px 3.2px rgba(0, 0, 0, .1);
  text-shadow: 0rem -.1rem .2rem rgba(0, 0, 0, .1);
}
