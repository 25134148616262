.notification-wrapper {
  position: fixed;
  top: 32px;
  top: 2rem;
  right: 0;
  z-index: 999;
}

.notification {
  padding: 32px 24px;
  padding: 2rem 1.5rem;
  color: white;
  border-top-left-radius: 4.8px;
  border-top-left-radius: .3rem;
  border-bottom-left-radius: 4.8px;
  border-bottom-left-radius: .3rem;
  -webkit-animation: slide_in .3s ease-out;
          animation: slide_in .3s ease-out;
  font-size: 32px;
  font-size: 2rem
}

.notification.success,
  .notification.Success {
  background-color: #67ce67;
}

.notification.warn,
  .notification.Warn {
  background-color: #cfb92b;
}

.notification.error,
  .notification.Error {
  background-color: #d2122c;
}

.notification.Failed,
  .notification.failed {
  background-color: #d2122c;
}

.notification.normal,
  .notification.Normal {
  background-color: #225290;
}

.notification-title {
    margin-left: 32px;
    margin-left: 2rem;
}

.notification-willunmount {
    -webkit-animation: slide_out .3s ease-out;
            animation: slide_out .3s ease-out;
}

@-webkit-keyframes slide_in {
  from {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide_in {
  from {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slide_out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }
}

@keyframes slide_out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }
}
