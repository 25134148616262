.template > i {
    font-size: 104px;
    font-size: 6.5rem;
    margin-bottom: 16px;
    margin-bottom: 1rem;
}
.template {
    background-color: #21252b;
    border-radius: 1.1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* padding: 2rem 3.5rem; */
    padding: 24px;
    padding: 1.5rem;
    padding-bottom: 16px;
    padding-bottom: 1rem;
    width: 240px;
    width: 15rem;
    height: 240px;
    height: 15rem;
    text-align: center;
    margin: 16px;
    margin: 1rem;
    border: solid 3.2px #21252b;
    border: solid .2rem #21252b;
}
.template:active {
    border-color: #ff6c16;
}
