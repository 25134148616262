.category {
  margin: 64px 0;
  margin: 4rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase
}

.category:first-of-type {
  margin-top: 0;
}

.category-open-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 160px;
    height: 10rem;
}

.category-open-btn-active .category-title {
      border: solid 3.2px #ff6c16;
      border: solid .2rem #ff6c16;
    }

.category-open-btn:hover i {
  opacity: 1;
    -webkit-transform: translateX(.5rem);
            transform: translateX(.5rem);
}

.category-open-btn i {
  font-size: 48px;
  font-size: 3rem;
  color: white;
  margin-left: 11.2px;
  margin-left: .7rem;
  opacity: .4;
  -webkit-transition: opacity .2s, -webkit-transform .2s;
  transition: opacity .2s, -webkit-transform .2s;
  transition: opacity .2s, transform .2s;
  transition: opacity .2s, transform .2s, -webkit-transform .2s;
}

.category-title {
    text-transform: uppercase;
    height: 100%;
    font-size: 32px;
    font-size: 2rem;
    width: 240px;
    width: 15rem;
    border-radius: .5rem;
    background-color: #21252b;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
            box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
    position: relative;
    padding: 16px;
    padding: 1rem;
}

.sub-category {
  margin: 32px 0;
  margin: 2rem 0
}

.sub-category .category {
  margin: 0 32px;
  margin: 0 2rem;
}

.sub-category .category-open-btn {
      height: 128px;
      height: 8rem;
}

.items-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
