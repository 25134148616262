.login-container {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #282c34;
}

.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;

  padding: 96px 272px;
  padding: 6rem 17rem;
  color: white;
  background-color: #21252b;
  border-radius: 1.1rem;
}

.login-form {
    width: 640px;
    width: 40rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center
  }

.login-form .joossens-button {
  margin-top: 64px;
  margin-top: 4rem;
    -ms-flex-item-align: center;
        align-self: center;
}

.configurator-titles {
  font-weight: bold;
  margin-bottom: 80px;
  margin-bottom: 5rem
}

.configurator-titles h1 {
  font-size: 64px;
  font-size: 4rem;
  letter-spacing: 14.4px;
  letter-spacing: .9rem;
}

.configurator-titles h2 {
  font-size: 19.2px;
  font-size: 1.2rem;
  letter-spacing: 22.72px;
  letter-spacing: 1.42rem;
}

.joossens-button {
  background-color: #ff6c16;
  border-radius: 10rem;
  padding: 16px 40px;
  padding: 1rem 2.5rem;
  font-size: 22.4px;
  font-size: 1.4rem;
  -webkit-transition: -webkit-filter .2s;
  transition: -webkit-filter .2s;
  transition: filter .2s;
  transition: filter .2s, -webkit-filter .2s
}

.joossens-button:hover {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.84252 0.14303999999999994 0.014439999999999995 0 0 0.04252 0.94304 0.014439999999999995 0 0 0.04252 0.14303999999999994 0.81444 0 0 0 0 0 1 0" /></filter></svg>#filter');
  -webkit-filter: grayscale(20%);
          filter: grayscale(20%);
}
