.dashboard-aside {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  color: white;
  height: 100%;
  width: 96px;
  width: 6rem;
  background-color: #21252b;
  -webkit-box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .5);
          box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .5);
  padding: 32px 0;
  padding: 2rem 0;
}

.dashboard-nav {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dashboard-nav-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  font-size: 35.2px;
  font-size: 2.2rem;
  padding: 16px;
  padding: 1rem;
}

.dashboard-nav-item-active {
      color: #ff6c16;
}

.dashboard-nav-item:nth-last-of-type(2) {
  margin-top: auto;
  -webkit-transition: -webkit-transform .1s;
  transition: -webkit-transform .1s;
  transition: transform .1s;
  transition: transform .1s, -webkit-transform .1s;
}

.dashboard-nav-item:nth-last-of-type(2):hover {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
}

.dashboard-nav-item:last-of-type {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.dashboard-nav-item:hover {
  color: #ff6c16;
}
