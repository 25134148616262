/* stylelint-disable */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 62.5%;
}
*, *::before, *::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
@font-face {
  font-family: 'gothicA';
  src: url('/assets/fonts/Alternate_Gothic_No2_BT.woff');
}
html,
body,
main,
.react-mount {
  min-height: 100vh;
}
body {
  font-family: gothicA, PT Sans, Helvetica neue, sans-serif;

  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
input,
textarea,
button,
select {
  outline: none;
  font-family: gothicA, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
[type='checkbox'] {
  -webkit-appearance: checkbox;
     -moz-appearance: checkbox;
          appearance: checkbox;
}
input[type='radio'] {
  -webkit-appearance: radio;
     -moz-appearance: radio;
          appearance: radio;
}
textarea {
  resize: none;
}
.erase-cursor {
  cursor: url(/assets/img/eraser.png), auto !important;
}
.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.upper {
  text-transform: uppercase;
}
.pointer {
  cursor: pointer;
}
.hide {
  display: none !important;
}
.no-scroll {
  overflow: hidden;
}
.cover {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.contain {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.flex-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
/* TEXT OPTIONS */
.unselectable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* END TEXT OPTIONS */
.capitalize {
  text-transform: capitalize;
}
.hide {
  display: none !important;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hide-file-input {
  width: 1px;
  width: .1rem;
  height: 1px;
  height: .1rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
  display: none;
}
