.dashboard-container {
  color: white;
  padding: 48px;
  padding: 3rem;
  min-height: 100vh;
  width: 100%;
  padding-left: 144px;
  padding-left: 9rem;
  background-color: #282c34
}

.dashboard-container .template a {
  background-color: #21252b;
}

.dashboard-container .button-action {
  margin: 0 16px;
  margin: 0 1rem;
}

.dashboard-title {
  font-size: 40px;
  font-size: 2.5rem;
  letter-spacing: 4.8px;
  letter-spacing: .3rem;
}

.file-edit {
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.sketch-list {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.folder-breadcrumbs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.folder-breadcrumbs ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 3.2px;
  letter-spacing: .2rem
}

.folder-breadcrumbs ul li span {
  font-size: 24px;
  font-size: 1.5rem;
  background: #ff6c16;
  padding: 8px;
  padding: .5rem;
  border-radius: .3rem;
  margin: 0 8px;
  margin: 0 .5rem;
}

.folder-breadcrumbs ul li .clickable:hover {
  cursor: pointer;
  background: white;
  color: #ff6c16;
}
