.sketch-tool {
  padding: 19.2px;
  padding: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 1.1rem;
  position: relative
}

.sketch-tool i {
  font-size: 24px;
  font-size: 1.5rem;
}

.sketch-tool:hover {
  background-color: #ff6c16;
}

.sketch-tool-extra {
    position: absolute;
    bottom: 8px;
    bottom: .5rem;
    right: 8px;
    right: .5rem;
    font-size: 19.2px;
    font-size: 1.2rem;
}

.selected-tool {
  background-color: #ff6c16;
}
