.settings-card-holder {
  background-color: #21252b;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 16px;
  margin: 1rem;
  border-radius: .5rem;
  width: 400px;
  width: 25rem;
  font-size: 24px;
  font-size: 1.5rem;
  padding: 8px;
  padding: .5rem;
  padding-left: 24px;
  padding-left: 1.5rem;
  -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3)
}
.settings-card-holder .button-action {
  padding: 11.2px;
  padding: .7rem;
  font-size: 22.4px;
  font-size: 1.4rem;
  margin: 0 4.8px;
  margin: 0 .3rem;
}
