.contextmenu {
  border-radius: .5rem;
  -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
  /* background-color: var(--darkGrey); */
  background-color: #f7f7f7;
  /* color: white; */
  color: black;
  position: absolute;
  z-index: 9999;
  font-size: 24px;
  font-size: 1.5rem;
}
