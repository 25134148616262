.new-sketch-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, .4);
}

.new-sketch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  color: white;
  border-radius: 1.1rem;
  background-color: #21252b;
  margin: 32px;
  margin: 2rem;
  padding: 0 !important;
  -webkit-box-align: unset !important;
      -ms-flex-align: unset !important;
          align-items: unset !important;
}

.create-sketch {
  padding: 96px 32px 32px 32px;
  padding: 6rem 2rem 2rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1040px;
  width: 65rem;
}

.templates {
  width: 100%;
  margin-bottom: 7%;
}

.templates-title {
    font-size: 27.2px;
    font-size: 1.7rem;
    letter-spacing: 1.6px;
    letter-spacing: .1rem;
  }

.templates-button {
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-left: 16px;
    margin-left: 1rem;
    margin-top: auto;
  }

.template-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-height: 576px;
  max-height: 36rem;
  overflow-y: scroll;
  margin-top: 16px;
  margin-top: 1rem;
}

.recent-sketches {
  border-top-right-radius: 17.6px;
  border-top-right-radius: 1.1rem;
  border-bottom-right-radius: 17.6px;
  border-bottom-right-radius: 1.1rem;
  width: 400px;
  width: 25rem;
  background-color: #282c34;
  padding-top: 64px;
  padding-top: 4rem;
  padding-bottom: 32px;
  padding-bottom: 2rem
}

.recent-sketches h2 {
  font-size: 32px;
  font-size: 2rem;
  letter-spacing: 3.2px;
  letter-spacing: .2rem;
  padding-left: 32px;
  padding-left: 2rem;
}

.recent-sketches-list {
    margin-top: 32px;
    margin-top: 2rem;
}
