.button-action {
  -webkit-box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
  color: white;
  padding: 16px;
  padding: 1rem;
  border-radius: .5rem;
  cursor: pointer;
  font-size: 27.2px;
  font-size: 1.7rem;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.84252 0.14303999999999994 0.014439999999999995 0 0 0.04252 0.94304 0.014439999999999995 0 0 0.04252 0.14303999999999994 0.81444 0 0 0 0 0 1 0" /></filter></svg>#filter');
  -webkit-filter: grayscale(.2);
          filter: grayscale(.2);
  background: #ff6c16;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}
.button-action p:first-of-type {
  margin-right: 8px;
  margin-right: .5rem;
}
.button-action > p:only-of-type {
  margin-right: 0 !important;
}
.button-action.blue {
  background: #225290;
}
.button-action.green {
  background: #67ce67;
}
.button-action.red {
  background: #d2122c;
}
.button-action:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button-action:hover {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.button-action:active {
  top: 1.6px;
  top: .1rem;
  -webkit-box-shadow: 0rem 0 .2rem rgba(0, 0, 0, .3);
          box-shadow: 0rem 0 .2rem rgba(0, 0, 0, .3);
}
