.contextmenu-item {
  padding: 16px 48px;
  padding: 1rem 3rem
}
.contextmenu-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-left-radius: .5rem;
  border-top-right-radius: 8px;
  border-top-right-radius: .5rem;
}
.contextmenu-item:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: 8px;
  border-bottom-right-radius: .5rem;
}
.contextmenu-item:hover {
  background-color: #ff6c16;
  color: white;
}
.contextmenu-item i {
  margin-right: 32px;
  margin-right: 2rem;
}
