.switch-container {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.switch {
  font-size: 48px;
  font-size: 3rem;
  margin-left: 32px;
  margin-left: 2rem
}

.switch .fa-toggle-on {
  color: #67ce67;
}
