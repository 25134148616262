.recent-sketch {
  padding: 32px;
  padding: 2rem;
  font-size: 20.8px;
  font-size: 1.3rem;
  letter-spacing: 1.6px;
  letter-spacing: .1rem
}
.recent-sketch:hover {
  background-color: #21252b;
}
