.modal-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, .3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 998;
}

.modal-content {
  background: #21252b;
  -webkit-box-shadow: 0 0 .3rem rgba(0, 0, 0, .3);
          box-shadow: 0 0 .3rem rgba(0, 0, 0, .3);
  min-height: 240px;
  min-height: 15rem;
  max-height: 95vh;
  overflow-y: scroll;
  border-radius: .5rem;
  min-width: 640px;
  min-width: 40rem;
  padding: 48px;
  padding: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.modal-content .divide-line {
  width: 100%;
  height: 3.2px;
  height: .2rem;
  background-color: grey;
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.modal-title {
  font-size: 32px;
  font-size: 2rem;
  margin-bottom: 32px;
  margin-bottom: 2rem;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-weight: 500;
}

.item-modal {
  width: 880px;
  width: 55rem;
}

.file-input-container {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 45%;
  height: 240px;
  height: 15rem;
  color: black;
  background-color: white;
  font-size: 80px;
  font-size: 5rem;
  border-radius: .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.preview-img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.file-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
