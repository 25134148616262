.sketch-container {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  padding-left: 96px;
  padding-left: 6rem;
  color: white;
  background-color: #282c34;
}

.sketch-header {
  background-color: #21252b;
  height: 64px;
  height: 4rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

.sketch-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: calc(100% - 4.6rem);
  margin-top: 9.6px;
  margin-top: .6rem;
}

.sketch-categories {
  min-width: 480px;
  min-width: 30rem;
  background-color: #21252b;
  border-top-left-radius: 17.6px;
  border-top-left-radius: 1.1rem;
  border-bottom-left-radius: 17.6px;
  border-bottom-left-radius: 1.1rem;
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  right: 0;
}

.sketch-library {
  width: 100%;
  max-height: 60%;
  overflow-y: scroll;
}

.sketch-canvas-container {
  width: calc(100% - 35rem);
  height: calc(100% - 7rem);
  margin: auto;
  background-color: white;
  border-radius: 1.1rem;
}

canvas {
  border-radius: 1.1rem;
}

.sketch-canvas {
  border-radius: 1.1rem;
  background-color: white;
  width: 100%;
  height: 100%;
}

.sketch-tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.save-sketch {
  font-size: 40px;
  font-size: 2.5rem;
  margin-right: 16px;
  margin-right: 1rem;
}

.sketch-title {
  font-size: 48px;
  font-size: 3rem;
  text-align: center;
  margin-left: 24px;
  margin-left: 1.5rem;
}

.item-information-container {
  padding: 16px;
  padding: 1rem;
  font-size: 22.4px;
  font-size: 1.4rem;
  padding-top: 32px;
  padding-top: 2rem;
  border-top: solid 3.2px #282c34;
  border-top: solid .2rem #282c34;
}

.information-content-title {
  font-size: 28.8px;
  font-size: 1.8rem;
  color: #ff6c16;
  font-weight: bold;
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.information-content-item {
  margin: 16px 0;
  margin: 1rem 0;
}

.client-info-item {
  margin: 16px;
  margin: 1rem;
}

.client-info-title {
  font-weight: bold;
  font-size: 32px;
  font-size: 2rem;
}

.tool-info li {
  margin: 0 16px;
  margin: 0 1rem;
  font-size: 24px;
  font-size: 1.5rem
}

.tool-info li label {
  margin-left: 16px;
  margin-left: 1rem;
}

.line-color {
  height: 32px;
  height: 2rem;
  width: 32px;
  width: 2rem;
}

.line-color-selected {
    border: solid 3.2px white;
    border: solid .2rem white;
  }

.items-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
