.item {
  padding: 32px;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #21252b;
  margin: 16px;
  margin: 1rem;
  font-size: 24px;
  font-size: 1.5rem;
  max-width: 320px;
  max-width: 20rem;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="1 0" /><feFuncG type="table" tableValues="1 0" /><feFuncB type="table" tableValues="1 0" /></feComponentTransfer></filter></svg>#filter');
}

  .item-img {
    width: 240px;
    width: 15rem;
    height: 160px;
    height: 10rem;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="1 0" /><feFuncG type="table" tableValues="1 0" /><feFuncB type="table" tableValues="1 0" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }

  .item-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .item-desc {
    margin-top: 16px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-style: italic;
  }
