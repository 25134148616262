.input-search-icon {
  position: absolute;
  font-size: 32px;
  font-size: 2rem;
  top: calc(50% - 1rem);
  left: 16px;
  left: 1rem;
}

.search-input-holder {
  margin: 0;
  position: relative;
}

.search-input {
  padding-left: 64px !important;
  padding-left: 4rem !important;
  background-color: #21252b;
  color: white;
}
